// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add this to your styles or CSS file */
li.active {
    color: #F90003; /* Set the desired text color for the active link */
    background-color: #FAEBEB;
    /* Add any other styles you want for the active link */
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/NavBar/Navbar.css"],"names":[],"mappings":"AAAA,wCAAwC;AACxC;IACI,cAAc,EAAE,mDAAmD;IACnE,yBAAyB;IACzB,sDAAsD;EACxD","sourcesContent":["/* Add this to your styles or CSS file */\nli.active {\n    color: #F90003; /* Set the desired text color for the active link */\n    background-color: #FAEBEB;\n    /* Add any other styles you want for the active link */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
