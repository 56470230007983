// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.roboto-font {
    font-family: 'Roboto', sans-serif;
    /* Add other styles specific to this component */
  }

  .para{
    font-family: "Roboto",sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/Components/Home/Home.css"],"names":[],"mappings":"AAEA;IACI,iCAAiC;IACjC,gDAAgD;EAClD;;EAEA;IACE,gCAAgC;AACpC","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');\n\n.roboto-font {\n    font-family: 'Roboto', sans-serif;\n    /* Add other styles specific to this component */\n  }\n\n  .para{\n    font-family: \"Roboto\",sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
