// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p{
    letter-spacing: normal;
    text-align: left;
    line-height: 1.5; /* Adjust as needed */
    font-weight: normal;
    font-size: 1rem; /* Adjust as needed */
    font-family: serif;
}
`, "",{"version":3,"sources":["webpack://./src/Components/BlogsPage/BlogsPage.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,gBAAgB;IAChB,gBAAgB,EAAE,qBAAqB;IACvC,mBAAmB;IACnB,eAAe,EAAE,qBAAqB;IACtC,kBAAkB;AACtB","sourcesContent":["p{\n    letter-spacing: normal;\n    text-align: left;\n    line-height: 1.5; /* Adjust as needed */\n    font-weight: normal;\n    font-size: 1rem; /* Adjust as needed */\n    font-family: serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
