// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img {
    height: 40px; 
    margin: 0 20px; /* Adjust margin as needed */
  }
  
  .logos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
  }
  
  .logos:before,
  .logos:after {
    content: '';
    position: absolute;
    top: 0;
    width: 250px; /* Adjust based on your design */
    height: 100%;
    z-index: 2;
  }
  
  .logos:hover .logos-slide {
    animation-play-state: paused;
  }
  
  .logos-slide {
    display: flex;
    animation: 25s slide infinite linear;
  }
  
  @keyframes slide {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/PartnerCarousel/PartnerCarousel.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,cAAc,EAAE,4BAA4B;EAC9C;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;;IAEE,WAAW;IACX,kBAAkB;IAClB,MAAM;IACN,YAAY,EAAE,gCAAgC;IAC9C,YAAY;IACZ,UAAU;EACZ;;EAEA;IACE,4BAA4B;EAC9B;;EAEA;IACE,aAAa;IACb,oCAAoC;EACtC;;EAEA;IACE;MACE,wBAAwB;IAC1B;IACA;MACE,4BAA4B;IAC9B;EACF","sourcesContent":[".img {\n    height: 40px; \n    margin: 0 20px; /* Adjust margin as needed */\n  }\n  \n  .logos {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 40px 0;\n    overflow: hidden;\n    white-space: nowrap;\n    position: relative;\n  }\n  \n  .logos:before,\n  .logos:after {\n    content: '';\n    position: absolute;\n    top: 0;\n    width: 250px; /* Adjust based on your design */\n    height: 100%;\n    z-index: 2;\n  }\n  \n  .logos:hover .logos-slide {\n    animation-play-state: paused;\n  }\n  \n  .logos-slide {\n    display: flex;\n    animation: 25s slide infinite linear;\n  }\n  \n  @keyframes slide {\n    0% {\n      transform: translateX(0);\n    }\n    100% {\n      transform: translateX(-100%);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
